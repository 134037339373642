/* AllEnteredPage.css */

.all-entered-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  height: 100dvh; /* Full viewport height */
  margin: 0 auto; /* Center horizontally */
  
  background-color: #fff;
  overflow-y: auto; /* Scroll if content overflows */
  font-size: 13px;
}


h1 {
  text-align: center;
  margin-bottom: 20px;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}



.filter-buttons button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.filter-buttons button:hover {
  background-color: #0056b3;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
  flex-direction: row;
  align-self: stretch;
}

.search-input {/* Full width */
  border: 1px solid #ccc;      /* Light border around the input */
  border-radius: 5px;         /* Rounded edges */
  padding: 10px 10px;           /* Padding for spacing */
  font-size: 12px;             /* Font size for the input */
  background-color: #ffffff;   /* Light background color */
  outline: none;                /* Remove outline on focus */
  width: 100%;
  max-width: 300px;
}

/* Optional: Style on focus */
.search-input:focus {
  border-color: #888;          /* Change border color on focus */
  background-color: #fff;      /* Change background to white on focus */
}

/* Custom placeholder styling */
.search-input::placeholder {
  color: #888;                  /* Gray color for the placeholder text */
  opacity: 1;                   /* Make sure the placeholder is fully opaque */
}


.filter-dropdown {
  position: relative;
  display: flex;
  margin: 20px 0px 20px 10px;
  gap: 20px;
  max-width: 300px;
}

.filter-button {
  background-color: #D9D9D9;
  color: black;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.filter-options {
  position: absolute;
  top: 40px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.filter-options button {
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.filter-options button:hover {
  background-color: #f1f1f1;
}




.custom-date-picker {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-date-picker input[type="date"] {
  margin: 0 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-date-picker button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.custom-date-picker button:hover {
  background-color: #218838;
}

.customers-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 20px 120px 10px;
  font-size: 1em;
}

.customers-table thead {
  background-color: #f2f2f2;
  color: #000000;
}

.customers-table th, .customers-table td {
  padding: 12px 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.customers-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.customers-table th {
  background-color: #f2f2f2;
  color: black;
}

.customers-table td {
  color: #333;
}

.editable {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  text-transform: capitalize;
}

.exit-time-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.exit-time-input:focus {
  outline: none;
  border-color: #007bff;
}

.download-csv {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;/* Dark background like in the image *//* Light border color */
}

.download-csv:hover {
  background-color: #2c2c2e; /* Slightly lighter background on hover */
}

.arrow-down-filter{         /* Space between arrow and text */
  font-size: 12px;
  margin-left: 5px;
  color: #80869A;
}
.arrow-down {
  margin-right: 4px;         /* Space between arrow and text */
  font-size: 18px;           /* Adjust arrow size */
}

.csv-text {
  font-size: 16px;
}



.add-button-all-customers{
  align-items: center;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  bottom: 80px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 50px;
}

mark {
  background-color: yellow;
  font-weight: bold;
}

.filter-button-container{
  display: flex;
  justify-content: center;
  height: 35px;
  
}

.search-container{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 35px; */
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Makes the icon circular */
  border: 1px solid darkgrey;
  color: gray; /* Icon color */
  font-size: 18px; /* Adjust the icon size */
}

@media (max-width: 600px) {
  /* Ensure responsiveness for small screens */
  .search-container {
    width: 90%;  /* Set a width that fits well on mobile */
    max-width: none; /* Remove max width restriction */
  }

  .search-input {
    width: 100%; /* Make search input full width */
  }

  .toolbar {
    flex-direction: row; /* Stack items in a column for better mobile view */
    align-items: center;
    margin-left: 0px;
  }
}


/* Button styles when in edit mode */
.status-button {
  border: none;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 4px;
}

.status-button.waiting {
  background-color: #f9f2cc;
  color: #856404;
  border: 1px solid #ffe8a1;
  margin-bottom: 5px;
}

.status-button.entered {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  margin-bottom: 5px;
}

.status-button.delete {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  margin-bottom: 5px;
}

.status-button.cancel {
  background-color: #e2e3e5;
  color: #6c757d;
  border: 1px solid #ced4da;
  margin-bottom: 5px;
}

/* Display mode label styles */
.status-label {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.status-label.active {
  background-color: #f9f2cc; /* Yellow for active */
  color: #856404;
  border: 1px solid #ffe8a1;
}

.status-label.entered {
  background-color: #d4edda; /* Green for entered */
  color: #155724;
  border: 1px solid #c3e6cb;
}

.status-label.inactive {
  background-color: #f8d7da; /* Red for inactive */
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.status-label.deleted {
  background-color: #f8d7da; /* Red for inactive */
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.dropdown-menu {
  
  top: 100%; /* Position it below the icon */
  right: 100%; /* Align it to the left of the icon */
  margin-right: 10px; /* Optional: Add some spacing */
  background: white; /* Adjust background as needed */
  border: 1px solid #ddd; /* Add a border */
  border-radius: 4px; /* Add rounded corners */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  z-index: 1000;
}

